import { useState } from "react";
import { NavLink } from "react-router-dom";

import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tabs, Tab } from "react-bootstrap";

import Layout from "../../../components/layout";
import Loading from "../../../components/loading";

import OCCache from "./oc";
import MOWCache from "../../../components/MowCache/";

const Cache = () => {
	const [loading, setLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState("oc");

	return (
		<Layout>
			<nav aria-label="breadcrumb">
				<NavLink exact={true} activeClassName="active" to="/" role="button">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<FontAwesomeIcon icon={faList} className="mr-2" />
							Gestión de Cache de OC y Stats de MOW
						</li>
					</ol>
				</NavLink>
			</nav>
			{loading && <Loading />}

			<Tabs
				activeKey={currentTab}
				onSelect={(k) => setCurrentTab(k)}
				className="custom-tabs"
			>
				<Tab eventKey="oc" title="Olecams cache">
					<OCCache />
				</Tab>
				<Tab eventKey="mow" title="MOW stats cache">
					<MOWCache />
				</Tab>
			</Tabs>
		</Layout>
	);
};

export default Cache;
