import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import "bootstrap/dist/css/bootstrap.min.css";

const ModalAlert = ({
	handleCancel = () => {},
	handleSuccess = () => {},
	labelSuccess = "Aceptar",
	labelCancel = "Cancelar",
	title = "No title",
	description = "No description",
	show = false,
}) => {
	return (
		<Modal show={show} onHide={handleCancel} centered>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{description}</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCancel}>
					{labelCancel}
				</Button>
				<Button variant="primary" onClick={handleSuccess}>
					{labelSuccess}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalAlert;
