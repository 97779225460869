import request from "./api-mow-service";

//day = YYYY-MM
function cacheMonthStats(year = null, month = null, email = null) {
	return request({
		url: `/admin/stats/cachemonthstats`,
		method: "POST",
		data: {
			year: year,
			month: month,
			email: email,
		},
	});
}

function copyCacheToProd(month, year) {
	return request({
		url: `/admin/stats/copystatscache?month=${month}&year=${year}`,
		method: "GET",
	});
}

const MowService = {
	copyCacheToProd,
	cacheMonthStats,
};

export default MowService;
