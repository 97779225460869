import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Alert from "react-bootstrap/Alert";

import MowService from "../../../services/mow-service";

import Form from "./Form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const MOWRegenerateCache = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	const [error, setError] = useState(null);
	const [done, setDone] = useState(false);

	const generateCache = async (month, year, email) => {
		setIsLoading(true);
		setError(null);
		MowService.cacheMonthStats(year, month, email)
			.then((data) => {
				console.log("### data", data);
				if (data[1] === 200) {
					setSuccessMessage(data[0]);
					toast.success(data[0]);
					setDone(true);
				} else {
					reset();
					toast.error(data[0]["error"]);
					setError(data[0]["error"]);
				}
			})
			.catch((e) => {
				reset();
				toast.error(e.message);
				setError(e.message);
			})
			.finally(() => setIsLoading(false));
	};

	const reset = () => {
		setDone(false);
		setIsLoading(false);
		setSuccessMessage(null);
		setError(null);
	};

	return (
		<>
			<h5 className="mb-3">Regenerar caché</h5>
			{error && (
				<Alert variant="danger" className="alert alert-error">
					{error}
				</Alert>
			)}

			{!isLoading && !done && (
				<Form onSubmit={generateCache} loading={isLoading} />
			)}

			{!isLoading && done && (
				<>
					<h6>¡Comienza la creación de la caché!</h6>
					<div className="d-flex my-2 align-items-center">
						<FontAwesomeIcon icon={faCheck} className="mr-2" />
						{successMessage}
					</div>
					<button
						onClick={(e) => {
							e.preventDefault();
							reset();
						}}
						className="btn btn-primary"
					>
						Entendido
					</button>
				</>
			)}
		</>
	);
};

export default MOWRegenerateCache;
