import { useState } from "react";
import { toast } from "react-toastify";

import MowService from "../../services/mow-service";

import ModalAlert from "../ModalAlert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const FormCopyCacheToProduction = () => {
	const today = new Date();
	const [isLoading, setIsLoading] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [showConfirm, setShowConfirm] = useState(false);

	const copyCacheToProd = () => {
		setIsLoading(true);
		MowService.copyCacheToProd(selectedMonth, selectedYear)
			.then(() => {})
			.catch(() => {
				setIsLoading(false);
				toast.error("Error al copiar la cache a prod");
			});
	};

	return (
		<>
			<h5 className="mb-3">Copiar caché a producción</h5>
			<div className="d-flex flex-column align-items-start">
				<label>
					1. Seleccione el mes del que desea copiar la caché a producción
				</label>
				<input
					type="month"
					min={`2024-01-01`}
					max={`${today.getFullYear()}-${today.getMonth() + 1}`}
					className="form-control"
					style={{ width: "auto" }}
					onChange={(e) => {
						if (!e.target.value) {
							setSelectedMonth(null);
							setSelectedYear(null);
							return;
						}
						setSelectedMonth(
							e.target.value.split("-")[1].toString().padStart(2, 0)
						);
						setSelectedYear(e.target.value.split("-")[0]);
					}}
				/>
			</div>
			{selectedMonth && selectedYear && (
				<>
					<div className="mb-4" />
					<div className="d-flex flex-column align-items-start">
						<label className="width-100">2. Copiar caché a producción</label>
						<button
							className="btn btn-primary mr-2"
							disabled={!selectedMonth}
							onClick={(e) => {
								e.preventDefault();
								setShowConfirm(true);
							}}
						>
							<span>
								<FontAwesomeIcon icon={faCheck} className="mr-2" /> Copiar la
								cache a producción del mes {selectedMonth}/{selectedYear}
							</span>
						</button>
					</div>
				</>
			)}

			<ModalAlert
				title="Copiar caché a producción"
				description={`¿Confirmas que deseas copiar la caché del mes ${selectedMonth}/${selectedYear} a producción?`}
				labelSuccess="Continuar"
				handleCancel={() => setShowConfirm(false)}
				handleSuccess={() => {
					setShowConfirm(false);
					copyCacheToProd();
				}}
				show={showConfirm}
			/>
		</>
	);
};

export default FormCopyCacheToProduction;
