import Alert from "react-bootstrap/Alert";

import FormCopyCacheToProduction from "./FormCopyCacheToProduction";
import MOWRegenerateCache from "./RegenerateCache";

const MOWCache = () => {
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col mt-3">
					<Alert variant="warning">
						<h6>¡Atención!</h6>
						<ul>
							<li>
								La caché será generada en staging para no afectar a los datos de
								producción.
							</li>
							<li>La caché se limpia antes de ser regenerada.</li>
							<li>
								El proceso quedará en segundo plano y notificará por email
								cuando haya finalizado.
							</li>
							<li>
								Cuando la caché se haya regenerado se recomienda comprobar que
								los datos son correctos antes de copiarla a producción. Usando
								staging API en el EMISOR o en MOW se puede ver los datos
								generados.
							</li>
							<li>
								La caché será regenerada para todos los días del mes
								seleccionado. En caso de tratarse del mes actual será hasta el
								día actual.
							</li>
							<li>
								Cuando se haya comprobado que los datos son correctos en staging
								se pueden copiar a producción. Esta acción no es reversible por
								lo que se recomienda usar esta herramienta con precaución.
							</li>
						</ul>
					</Alert>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="card shadow p-4">
						<MOWRegenerateCache />
					</div>
				</div>
				<div className="col">
					<div className="card shadow p-4">
						<FormCopyCacheToProduction />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MOWCache;
